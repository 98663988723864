import * as Schemas from './schemas';
import * as Yup from 'yup';

import { ValidationErrors, setIn } from 'final-form';
import { ValidationWorkerMessage, WorkerValidationOutput } from './types';

import { sentenceCase } from 'change-case';

Yup.setLocale({
  mixed: {
    default: 'Invalid value'
  }
});

/**
 * When using a default value for FormData types,
 * required will never do anything as doesn't work on empty string
 */
Yup.addMethod(Yup.string, 'required', function (errorMessage) {
  return this.test('meaningful-value', errorMessage, function (value) {
    const { path, createError } = this;

    if (!value || value.length < 2) {
      const fields = path.split('.');
      const field = sentenceCase(fields[fields.length - 1]);
      return createError({
        path,
        message: errorMessage || `A ${field} is required`
      });
    }

    return true;
  });
});

/**
 *
 * TODO: it's possible that this folding function needs refactoring for svelte forms
 * Tracked in https://app.clickup.com/t/865ct2vzd
 *
 * Sets the `innerError.message` in an `errors` object at the key
 * defined by `innerError.path`.
 * @param {Object} errors The object to set the error in.
 * @param {{ path: string, message: string }} innerError A `yup` field error.
 * @returns {Object} The result of setting the new error message onto `errors`.
 */
const setInFinalFormError = (
  errors: ValidationErrors,
  innerError: Yup.ValidationError
) => {
  const { path, message } = innerError;
  if (path && message && errors) {
    return errors && setIn(errors, path, message);
  }
};

/**
 * Empty object map with no prototype. Used as default
 * value for reducing the `err.inner` array of errors
 * from a `yup~ValidationError`.
 * @type {Object}
 */
const emptyObj = Object.create(null);

/**
 * Takes a schema name and values.
 * If the validation passes, the function returns undefined.
 * If the validation doesn't pass, it returns a map of invalid field names to errors.
 */
export default function validate({
  id,
  schemaName,
  values
}: ValidationWorkerMessage): WorkerValidationOutput {
  try {
    const schema = Schemas[schemaName];

    schema.validateSync(values, { abortEarly: false });
    return { id };
  } catch (err) {
    const errors = (err as Yup.ValidationError).inner.reduce(
      setInFinalFormError,
      emptyObj
    );
    return { id, errors };
  }
}
