import * as Yup from 'yup';

export const confirmNewsletterSchema = Yup.object({
  email: Yup.string()
    .required('An email is required')
    .email('Invalid email address'),
  confirmationToken: Yup.string().required()
});

export const confirmNewsletterWithDiscountSchema =
  confirmNewsletterSchema.concat(
    Yup.object({
      lt: Yup.string().required('Login token is required'),
      did: Yup.string().required('Discount id is required'),
      uid: Yup.string().required('Uid is required')
    })
  );
